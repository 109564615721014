body {
  background-color: #131416;
  font-family: "Wonderlabs";
}

main {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

header {
  padding-top: 4rem;
}

section.home {
  animation: fadeIn 5s;
  text-transform: uppercase;
  color: whitesmoke;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 14rem;
}

h1.home {
  font-weight: lighter;
  line-height: 0.85;
  font-size: 4.2vw;
}

h1.notfound {
  font-weight: bolder;
  text-align: center;
  font-size: 7rem;
}

p.date {
  font-weight: lighter;
  color: gray;
}

.gg-arrow-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 1rem;
  height: 1rem;
  color: gray;
  margin-left: auto;
  margin-right: auto;
}

.gg-arrow-left::after,
.gg-arrow-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
}

.gg-arrow-left::after {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  bottom: 7px;
}

.gg-arrow-left::before {
  width: 16px;
  height: 2px;
  bottom: 10px;
  background: currentColor;
}

section.blog {
  animation: fadeIn 5s;
  text-transform: uppercase;
  color: whitesmoke;
  margin-left: auto;
  width: 78%;
  margin-right: auto;
  padding-top: 7rem;
}

section.blogentry {
  animation: fadeIn 5s;
  text-transform: uppercase;
  color: whitesmoke;
  line-height: 1.35rem;
  margin-left: auto;
  width: 78%;
  margin-right: auto;
  padding-top: 7rem;
}

article.blog {
  line-height: 0.85;
}

section.fund {
  text-transform: uppercase;
  color: whitesmoke;
  margin-left: auto;
  margin-right: auto;
  padding-top: 7rem;
}

.btn {
  position: relative;
  text-transform: uppercase;
  color: whitesmoke;
  font-size: 1.4rem;
  cursor: pointer;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.btn:hover {
  color: whitesmoke;
  text-decoration: none;
}

.btn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.105rem;
  left: 0;
  bottom: 0;
  visibility: hidden;
  background-color: whitesmoke;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.btn:hover::before {
  width: 100%;
  transform: scaleX(1);
  visibility: visible;
}

.btn.active {
  position: relative;
}

.btn.active::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.105rem;
  left: 0;
  bottom: 0;
  background-color: whitesmoke;
  transition: all 0.3s ease-in-out;
}

/* Loading */

section.loading {
  top: 30%;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
}

.loading-img {
  position: fixed;
  top: 25%;
  left: 40%;
  width: 25vw;
  height: auto;
  transform: translate(-50%, -50%);
  animation: spin 3s;
}

.footer {
  bottom: 5px;
}

/*  Mobile  */
@media (max-width: 500px) {
  main {
    margin-left: auto;
    margin-right: auto;
  }

  header {
    padding-top: 4rem;
  }

  section.home {
    animation: fadeIn 5s;
    text-transform: uppercase;
    color: whitesmoke;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5rem;
  }

  .btn {
    position: relative;
    text-transform: uppercase;
    color: whitesmoke;
    transition: all 0.5s ease-in;
    font-size: 1.1rem;
    cursor: pointer;
  }

  h1.home {
    font-weight: lighter;
    text-align: center;
    line-height: 0.85;
    font-size: 4.2vw;
  }

  section.loading {
    top: 30%;
    left: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  img.logo {
    display: block;
    margin: 0 auto;
  }

  .loading-img {
    position: fixed;
    top: 25%;
    left: 35%;
    width: 35vw;
    height: auto;
    transform: translate(-50%, -50%);
    animation: spin 3s;
  }

  section.blog {
    animation: fadeIn 5s;
    text-transform: uppercase;
    text-align: center;
    color: whitesmoke;
    margin-left: auto;
    width: 78%;
    margin-right: auto;
    padding-top: 7rem;
  }
  
  section.blogentry {
    animation: fadeIn 5s;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.35rem;
    color: whitesmoke;
    margin-left: auto;
    width: 78%;
    margin-right: auto;
    padding-top: 7rem;
  }
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid gray;
}

/* Keyframes */

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@font-face {
  font-family: "Wonderlabs";
  src: url(../public/assets/AlteHaasGroteskRegular.ttf);
}
